<template>
  <div>
    <div class="table-controls">
      <div class="controls-left">
        <div v-if="anyChangesMade" class="editingActions">
          <b-button
            variant="primary"
            size="sm"
            class="btn mr20 mtb20 btn--actions"
            style="color: white"
            @click="saveChanges()"
            >Save Changes</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            class="btn mr20 mtb20 btn--actions"
            style="color: white"
            @click="setLocalOrder(order)"
            >Cancel</b-button
          >
        </div>
      </div>
      <div class="controls-right">
        <v-icon
          id="copyDetails"
          name="copy" 
          style="width: 1.3rem; height: 1.3rem; opacity: 0.5;" 
          @click="copyDetails()"
        ></v-icon>
        <b-tooltip target="copyDetails" triggers="hover">
          Copy details to clipboard
        </b-tooltip>
      </div>
    </div>
    <b-table
      id="detailsTable"
      thead-class="hiddenHeader"
      sticky-header
      bordered
      small
      :fields="fields"
      :items="condTableHeaders"
      responsive="sm"
    >
      <!-- A virtual column -->
      <template #cell(student_details)="{ item }">
        <label style="font-size: 0.75rem;" v-if="!item.key2" :for="item.key">{{ item.name }}</label>
        <label style="font-size: 0.75rem;" v-else :for="item.key2">{{ item.name }}</label>
      </template>
      <template #cell(information)="{ item }">
        <!-- If not editable -->
        <b-form-input
          type="text"
          v-if="!item.editable"
          :id="item.key"
          :value="formatTableCell(item)"
          :disabled="!item.editable"
          style="font-size: 0.75rem; border: 0; height: 20px"
        />
        <!-- Else editable and one key-->
        <b-form-input
          type="text"
          v-else-if="!item.key2"
          :id="item.key"
          v-model="localOrder[item.key]"
          :disabled="!item.editable"
          style="font-size: 0.75rem; border: 0; height: 20px"
        />
        <!-- Else two keys-->
        <b-form-input
          type="text"
          v-else
          :id="item.key"
          v-model="localOrder[item.key][item.key2]"
          :disabled="!item.editable || !canEditCustomer()"
          style="font-size: 0.75rem; border: 0; height: 20px"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    order: {},
  },
  data() {
    return {
      detailsToCopy: "",
      localOrder: {},
      tableHeaders: [
        { key: "customer", key2:"first_name", name: "First Name", editable: true },
        { key: "middle_name",  name: "Middle Name", editable: true },
        { key: "customer", key2:"last_name", name: "Last Name", editable: true },
        { key: "email", name: "Email", editable: true },
        { key: "phone_1", name: "Phone", editable: true },
        { key: "phone_2", name: "Phone 2", editable: true },
        { key: "induction_status", name: "Induction Program Status", editable: true, cond: true },
        { key: "induction_completion_date", name: "Induction Completion Date", editable: true, cond: true },
        { key: "credit_years", name: "Program Year", editable: true, cond: true },
        { key: "pasc_program_status", name: "PASC Program Status", editable: true, cond: true },
        { key: "student_job_title", name: "Job Title", editable: true, cond: true },
        {
          key: "student_previously_enrolled",
          name: "Previously Enrolled at Pacific?",
          editable: true,
        },
        {
          key: "student_school_district",
          name: "School District",
          editable: true,
        },
        {
          key: "student_degree_earned",
          name: "Highest Degree",
          editable: true,
        },
        {
          key: "student_graduating_school",
          name: "Where did you earn your degree?",
          editable: true,
        },
        { key: "address_1", name: "Street Address", editable: true },
        { key: "address_2", name: "Apartment/Suite", editable: true },
        { key: "city", name: "City", editable: true },
        { key: "zipcode", name: "Zipcode", editable: true },
        { key: "state", name: "State", editable: true },
        { key: "country", name: "Country", editable: true },
        { key: "customer", key2: "date_of_birth", name: "Date of Birth", editable: true },
        {
          key: "course_beginning_date",
          name: "Course Beginning Date",
          editable: true,
        },
        {
          key: "course_ending_date",
          name: "Course Ending Date",
          editable: true,
        },
        { key: "no_refund_policy", name: "No Refund Policy", editable: false, cond: true },
        {
          key: "course_completion_date",
          name: "Course Completion Date",
          editable: false,
        },
        {
          key: "grade_type",
          name: "Grade Submission Type",
          editable: false,
          boolean: true,
        },
        {
          key: "course_status",
          name: "Course Status",
          editable: false,
          boolean: true,
        },
        { key: "course_grade", name: "Course Grade", editable: false },
        {
          key: "course",
          key2: "course_number",
          name: "Course Number",
          editable: false,
        },
        {
          key: "course",
          key2: "course_title",
          name: "Course Title",
          editable: false,
        },
        { key: "course_credits", name: "Credits", editable: false },
        {
          key: "course_credit_fee",
          name: "Credit Fee (Price)",
          editable: false,
        },
        {
          key: "course_fixed_credit_fee",
          name: "Fixed Credit Fee (Price)",
          editable: false,
        },
      ],
      fields: [{ key: "student_details" }, { key: "information" }],
    };
  },
  methods: {
    saveChanges() {
      this.$emit("updateOrder", this.updateObj);
    },
    setLocalOrder(newOrder) {
      if (!newOrder) return;
      this.localOrder = JSON.parse(JSON.stringify(newOrder));
    },
    formatTableCell(item) {
      if (item.key2) return this.localOrder[item.key][item.key2];
      if (item.key == "course_status")
        return this.localOrder[item.key] ? "Completed" : "In-Progress";
      if (item.key == "grade_type")
        return this.localOrder[item.key]===null ? "" : (this.localOrder[item.key] ? "UOP Banner" : "Inside Pacific")
      if (item.key === "course_completion_date" && this.localOrder[item.key])
        return this.formatDate(this.localOrder[item.key]);
      if (item.key.includes("fee")) return "$" + this.localOrder[item.key];
      return this.localOrder[item.key];
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        timeZone: "America/Los_Angeles",
      });
    },
    canEditCustomer() {
      return this.$store.state.user && this.$store.state.user.can_edit_customer;
    },
    copyDetails() {
      var detailsArray = [
        {
          title: "UOP Student ID",
          value: this.localOrder.customer ? this.localOrder.customer.uop_student_id : ""
        },
        {
          title: "First Name",
          value: this.localOrder.customer ? this.localOrder.customer.first_name : ""
        },
        {
          title: "Last Name",
          value: this.localOrder.customer ? this.localOrder.customer.last_name : ""
        },
        {
          title: "DOB",
          value: this.localOrder.customer ? this.localOrder.customer.date_of_birth : ""
        },
        {
          title: "Email",
          value: this.localOrder.email
        },
        {
          title: "Phone Number",
          value: this.localOrder.phone_1
        },
        {
          title: "Order Date",
          value: this.localOrder.order_date ? 
                 this.formatDate(this.localOrder.order_date) :
                 ""
        },
        {
          title: "Processed Date",
          value: this.localOrder.uop_processed_date ? 
                 this.formatDate(this.localOrder.uop_processed_date) :
                 ""
        },
        {
          title: "Cashnet TX ID",
          value: (this.localOrder.cashnet_transactions && this.localOrder.cashnet_transactions.length > 0) ? 
                 this.localOrder.cashnet_transactions.map(e => e.id).join(', ') : ""
        },
        {
          title: "Course Number",
          value: this.localOrder.course ? this.localOrder.course.course_number : ""
        },
        {
          title: "Credits",
          value: this.localOrder.course_credits
        },
        {
          title: "Credit Fee",
          value: "$"+this.localOrder.course_credit_fee
        },
        {
          title: "Course Grade",
          value: this.localOrder.course_grade
        },
        {
          title: "Course Completion Date",
          value: this.localOrder.course_completion_date ? 
                 this.formatDate(this.localOrder.course_completion_date) :
                 ""
        },
        {
          title: "UOP Banner Registration",
          value: this.localOrder.banner_status ? "Registered" : "Not-Registered"
        },
        {
          title: "UOP Registration Date",
          value: this.localOrder.uop_banner_registration_date ? 
                 this.formatDate(this.localOrder.uop_banner_registration_date) : 
                 ""
        },
      ]
      var detailsString = ""
      for(const detail of detailsArray) {
        detailsString += detail.title+": "
        detailsString += detail.value
        detailsString += "\n"
      }
      console.log(detailsString)
      navigator.clipboard.writeText(detailsString);

    }
  },
  computed: {
    condTableHeaders() {
      var condTableHeaders = [];
      for (const item of this.tableHeaders) {
        if(!Object.prototype.hasOwnProperty.call(item, 'cond') || !item.cond) {
          condTableHeaders.push(item);
          continue
        }
        if(!this.localOrder.wc_order_id) continue;
        if(!this.localOrder[item.key] || this.localOrder[item.key] == "") continue;
        condTableHeaders.push(item)


      }
      return condTableHeaders

    },
    anyChangesMade() {
      return JSON.stringify(this.localOrder) !== JSON.stringify(this.order);
    },
    updateObj() {
      const obj = {};
      for (let th = 0; th < this.tableHeaders.length; th++) {
        const header = this.tableHeaders[th].key;
        if (header == "") continue;
        if (!this.tableHeaders[th].editable) continue;
        if (this.localOrder[header] !== this.order[header])
          obj[header] = this.localOrder[header];
      }
      // console.log("DETAILS OBJ", obj);
      return { _id: this.localOrder._id, ...obj };
    },
  },
  watch: {
    order: {
      handler() {
        this.setLocalOrder(this.order);
      },
      deep: true,
    },
  },
  created() {
    this.setLocalOrder(this.order);
  },
};
</script>


<style lang="scss" scoped>
#detailsTable {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  column-width: 700px;
}
#detailsTable td {
  border: 1px solid black;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  text-align: center;
  font-size: 0.75rem;
  label {
    width: 100%;
    text-align: start;
    padding-left: 10px;
    margin: 0;
  }
  input {
    font-size: 0.75rem;
  }
}

th {
  margin: 0;
  width: 10rem;
  text-overflow: clip;
  border: 1px solid #c7c7c7;
  text-align: start;
  padding-left: 10px;
}
.table-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-color: "#c7c7c7";

  .controls-right,
  .controls-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .editingActions {
      display: flex;
      gap: 10px;
      button {
        padding: 0 20px;
      }
    }
  }
}
</style>
<style>
#detailsTable .hiddenHeader {
  display: none;
}
</style>